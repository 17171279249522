
import { defuFn } from '/app/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "firebaseConfig": {
    "apiKey": "AIzaSyC6QWs_OOE9bX8L4BmJlsF8qZf0cinPTng",
    "authDomain": "enee-hn.firebaseapp.com",
    "databaseURL": "https://enee-hn-default-rtdb.firebaseio.com",
    "projectId": "enee-hn",
    "storageBucket": "enee-hn.appspot.com",
    "messagingSenderId": "983231514624",
    "appId": "1:983231514624:web:b5f6cf9f033d5b1010f0e5",
    "measurementId": "G-N81FNMTK4R"
  },
  "vuefireOptions": {
    "optionsApiPlugin": false,
    "emulators": {
      "enabled": true
    },
    "config": {
      "apiKey": "AIzaSyC6QWs_OOE9bX8L4BmJlsF8qZf0cinPTng",
      "authDomain": "enee-hn.firebaseapp.com",
      "databaseURL": "https://enee-hn-default-rtdb.firebaseio.com",
      "projectId": "enee-hn",
      "storageBucket": "enee-hn.appspot.com",
      "messagingSenderId": "983231514624",
      "appId": "1:983231514624:web:b5f6cf9f033d5b1010f0e5",
      "measurementId": "G-N81FNMTK4R"
    }
  }
}



export default /* #__PURE__ */ defuFn(inlineConfig)
